import React from 'react'
import PropTypes from 'prop-types'
import Helmet from 'react-helmet'
import styled, { ThemeProvider } from 'styled-components'
import { graphql } from 'gatsby'
import Img from 'gatsby-image'
import config from '../../config/website'
import { colors } from '../../tailwind'

// Components
import Menu from '../components/Menu'
import Layout from '../components/Layout'
import TagList from '../components/TagList'
import Footer from '../components/Footer'

// Elements
import {
  ProjectInner,
  HeaderBigBox,
  BigBox,
  ImageContainer,
  ImageContainerFull,
  ImageGrid,
  ProjectHeader,
  ProjectDescription,
  ProjectDate,
  ProjectTitle,
  ProjectRow,
  ProjectSection,
  ProjectSectionTitle,
  ProjectSectionText,
} from '../elements/Projects'
import { StatsContainer, Stat, BigNumber, Unit, Text } from '../elements/Stats'
import NextProject from '../components/NextProject'

import MassArtDemoVideo from '../videos/massart/massart-demo.mp4'
import { StyledLinkOut } from '../elements/Links'

const Video = styled.video`
  position: absolute;
  top: 50%;
  left: 50%;
  width: 84%;
  transform: translate(-44%, -68%);
`

const theme = {
  menu: colors.black,
  menuHover: colors.white,
  accent: colors['am-orange'],
  ally: colors['am-orange-ally'],
}

const stats = [
  { number: 87, unit: '%', text: 'increase in unique page views' },
  { number: 2, unit: '⨉', text: 'more traffic' },
  { number: 4, unit: '%', text: 'increase in time spent on key pages' },
]

const realPrefix = config.pathPrefix === '/' ? '' : config.pathPrefix
const homeURL = `${config.siteUrl}${realPrefix}`
const image = `${homeURL}/massart.jpg`

const Page = ({ data }) => (
  <ThemeProvider theme={theme}>
    <Layout>
      <Helmet>
        <meta property="og:image" content={image} />
      </Helmet>
      <Menu color={colors.white} />
      <HeaderBigBox>
        <ProjectInner>
          <ImageContainer>
            <Img fluid={data.MassArt.childImageSharp.fluid} />
          </ImageContainer>
        </ProjectInner>
      </HeaderBigBox>
      <ProjectInner>
        <ProjectHeader>
          <ProjectDate>Spring 2016</ProjectDate>
          <ProjectTitle>MassArt.edu</ProjectTitle>
          <ProjectDescription>A website redesign of MassArt that includes the student body’s voice.</ProjectDescription>
          <TagList tags={['UI', 'Responsive Web']} />
        </ProjectHeader>
        <ProjectRow>
          <ProjectSection>
            <ProjectSectionTitle>Inception</ProjectSectionTitle>
            <ProjectSectionText>
              Massachusetts College of Art and Design asked{' '}
              <StyledLinkOut href="https://www.ifactory.com/" target="_blank" rel="noopener noreferrer">
                iFactory
              </StyledLinkOut>
              , a boston-based web design agency, to re-design{' '}
              <StyledLinkOut href="https://massart.edu" target="_blank" rel="noopener noreferrer">
                massart.edu
              </StyledLinkOut>{' '}
              into a beautiful and functional site for parents, students, and faculty.
            </ProjectSectionText>
          </ProjectSection>
        </ProjectRow>
        <ProjectRow>
          <ProjectSection>
            <ProjectSectionTitle>My contribution</ProjectSectionTitle>
            <ProjectSectionText>
              I did visual design on a team alongside two visual designers and one UX researcher. I pitched art
              direction to our project stakeholders and created high-fidelity screens for desktop, tablet, and mobile
              sizes.
            </ProjectSectionText>
          </ProjectSection>
          <ProjectSection>
            <ProjectSectionTitle>Tools</ProjectSectionTitle>
            <ProjectSectionText>
              <ul>
                <li>Photoshop</li>
              </ul>
            </ProjectSectionText>
          </ProjectSection>
        </ProjectRow>
      </ProjectInner>
      <ImageContainerFull>
        <Img fluid={data.group.childImageSharp.fluid} />
      </ImageContainerFull>
      <ProjectInner>
        <ProjectRow>
          <ProjectSection>
            <ProjectSectionTitle>Diving in</ProjectSectionTitle>
            <ProjectSectionText>
              With the project underway, I joined the visual design team to mock up visual concepts to see how far an
              art school would stretch their boundaries. Similar to concept cars, below are some of the concepts I
              created to see what style our client was looking to expand on. Since the client was an art school, I was
              particularly excited to see how open they would be to seeing out of the box ideas.
            </ProjectSectionText>
          </ProjectSection>
        </ProjectRow>
        <ProjectRow>
          <ImageContainer>
            <Img fluid={data.dive.childImageSharp.fluid} />
          </ImageContainer>
        </ProjectRow>
        <ProjectRow>
          <ProjectSection>
            <ProjectSectionTitle>Pitching our work</ProjectSectionTitle>
            <ProjectSectionText>
              After initial feedback from our clients, I, alongside two other designers, created homepage designs to
              pitch to both the incoming and outgoing presidents of MassArt, and to the students and faculty at two town
              halls.
            </ProjectSectionText>
            <ProjectSectionText>
              Pitching allowed me great experience to stand up for my work and hear feedback from the users who will be
              visiting the site everyday. At each town hall, 30 to 60 students and faculty showed up to voice their
              opinion on our three options.
            </ProjectSectionText>
          </ProjectSection>
        </ProjectRow>
        <ProjectRow>
          <ProjectSection>
            <ProjectSectionTitle>Moving forward in one direction</ProjectSectionTitle>
            <ProjectSectionText>
              Once we solidified our concept, I moved forward with one other designer to create screens for key pages on
              the site. We created desktop, tablet, and mobile screens for each page to ensure our designs would display
              well on each size.
            </ProjectSectionText>
            <ProjectSectionText>
              I shadowed the UX team as they iterated and tested their wireframes. Once they validated their directions,
              key pages would be given to the visual team to bring to high fidelity and show to our client.
            </ProjectSectionText>
          </ProjectSection>
        </ProjectRow>
      </ProjectInner>
      <BigBox>
        <ImageGrid style={{ padding: '4rem 0' }}>
          <ImageContainer style={{ maxWidth: '60%' }}>
            <Img fluid={data.imac.childImageSharp.fluid} />
            <Video autoPlay muted loop controls playsinline>
              <track kind="captions" />
              <source src={MassArtDemoVideo} type="video/mp4" />
              Your browser does not support the video tag.
            </Video>
          </ImageContainer>
          <ImageContainer style={{ maxWidth: '25%' }}>
            <Img fluid={data.iphone.childImageSharp.fluid} />
          </ImageContainer>
        </ImageGrid>
      </BigBox>
      <ProjectInner>
        <ProjectRow>
          <ProjectSection>
            <ProjectSectionTitle>Seeing the project through</ProjectSectionTitle>
            <ProjectSectionText>
              I created a design specification to outline user interactions and ensure the developers had no questions
              when taking our design to code. As the pages were being developed, I performed quality assurance and
              logged tickets back to the dev team before the site was launched.
            </ProjectSectionText>
            <ProjectSectionText>Our efforts led to these results:</ProjectSectionText>
          </ProjectSection>
          <StatsContainer>
            {stats.map(({ number, unit, text }) => (
              <Stat>
                <div>
                  <BigNumber>{number}</BigNumber>
                  <Unit>{unit}</Unit>
                </div>
                <Text>{text}</Text>
              </Stat>
            ))}
          </StatsContainer>
        </ProjectRow>
        <ImageContainer>
          <Img fluid={data.groupFinal.childImageSharp.fluid} />
        </ImageContainer>
      </ProjectInner>
      <NextProject to="/orchestration" bg={colors['am-purple']} color={colors['am-purple-ally']}>
        Platform-wide orchestration
      </NextProject>
      <Footer />
    </Layout>
  </ThemeProvider>
)

Page.propTypes = {
  data: PropTypes.object.isRequired,
}

export default Page

export const pageQuery = graphql`
  query {
    MassArt: file(relativePath: { eq: "projects/massart.png" }) {
      childImageSharp {
        fluid(maxWidth: 700) {
          ...GatsbyImageSharpFluid_withWebp_noBase64
        }
      }
    }
    group: file(relativePath: { eq: "massart/massart-group.jpg" }) {
      childImageSharp {
        fluid(maxWidth: 1980) {
          ...GatsbyImageSharpFluid_withWebp
        }
      }
    }
    dive: file(relativePath: { eq: "massart/massart-dive.png" }) {
      childImageSharp {
        fluid(maxWidth: 900) {
          ...GatsbyImageSharpFluid_withWebp
        }
      }
    }
    imac: file(relativePath: { eq: "massart/massart-imac.png" }) {
      childImageSharp {
        fluid(maxWidth: 600) {
          ...GatsbyImageSharpFluid_withWebp
        }
      }
    }
    iphone: file(relativePath: { eq: "massart/massart-iphone.png" }) {
      childImageSharp {
        fluid(maxWidth: 400) {
          ...GatsbyImageSharpFluid_withWebp
        }
      }
    }
    groupFinal: file(relativePath: { eq: "massart/massart-group-final.jpg" }) {
      childImageSharp {
        fluid(maxWidth: 900) {
          ...GatsbyImageSharpFluid_withWebp
        }
      }
    }
  }
`
